* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Inter", sans-serif;
}

.App {
  max-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

p {
  margin: 0;
  padding: 0;
}

.desktop-version {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  background-image: url("./images//all/desktop_bg.png");
  background-repeat: no-repeat;
  background-position: 0px;
  background-size: cover;
  position: relative;
}

.desktop-version .Surevey-main-container {
  background-color: white;
}

.desktop-version .Surevey-main-container,
.desktop-version .userSurvey-main {
  height: 90vh !important;
  margin-top: 5vh;
  border-radius: 8px;
  /* overflow: hidden; */
  max-width: 768px !important;
}

.desktop-version .smytten-logo {
  width: 110px;
  height: 55px;
  position: absolute;
  top: 40px;
  left: 60px;
}

.mobile-only {
  display: none !important;
}

.desktop-only {
  display: block !important;
  z-index: 5;
}

.desktop-version .recrutement-page-main-container {
  max-width: 780px !important;
  height: 90vh !important;
  margin-top: 5vh !important;
  border-radius: 8px !important;
  overflow: hidden;
  z-index: 5;
}

.desktop-version .questions-main-container {
  max-width: 780px !important;
  height: 90vh !important;
  margin-top: 5vh !important;
  border-radius: 8px !important;
  overflow: hidden;
  z-index: 5;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .desktop-version .Surevey-main-container,
  .desktop-version .userSurvey-main {
    height: 100vh !important;
    margin-top: 0vh !important;
    border-radius: 0px;
    /* overflow: hidden; */
    max-width: 768px !important;
  }
  .desktop-version {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    background-image: none !important;
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: cover;
    position: relative;
  }
  .desktop-version .smytten-logo {
    width: 110px;
    height: 55px;
    position: absolute;
    top: 40px;
    left: 60px;
    display: none !important;
  }
  .desktop-version .questions-main-container {
    max-width: 500px !important;
    height: 100vh !important;
    margin-top: 0% !important;
    border-radius: 0px !important;
  }
  .mobile-only {
    display: block !important;
  }

  .desktop-only {
    display: none !important;
  }

  .desktop-version .recrutement-page-main-container {
    max-width: 500px !important;
    height: 100% !important;
    margin-top: 0% !important;
    border-radius: 0px !important;
    padding: 0px;
  }
}

/* ___________ */

.testMain {
  max-height: 100vh;
  width: 100vh;
  background-color: pink;
  object-fit: contain;
}
.testNav {
  background-color: black;
  height: 2rem;
}
.testBody {
  height: calc(100% - 4rem);
  overflow: scroll;
}
.testBottom {
  background-color: black;
  height: 2rem;
}

.chart {
  width: 200px;
  height: 200px;

  /* border: 1px solid red; */
}

.val {
  font-size: 13px;
}
