.flex-flex-start-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-flex-start-flex-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-flex-start-flex-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-space-between-flex-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.cursor-p {
  cursor: pointer;
}

.mr-10px {
  margin-right: 10px;
}

.p-16px {
  padding: 16px;
}
.mt-10px {
  margin-top: 10px;
}

.border-red {
  border: 1px solid red;
}
.border-blue {
  border: 1px solid blue;
}
.border-green {
  border: 1px solid green;
}

.position-relative {
  position: relative;
}

.width-100 {
  width: 100% !important;
}
.height-100 {
  height: 100% !important;
}

.width-50 {
  width: 50% !important;
}
.height-50 {
  height: 50% !important;
}

.mr-8px {
  margin-right: 8px;
}

.text-left {
  text-align: left;
}
.mt-16px {
  margin-top: 16px;
}

.mt-8px {
  margin-top: 8px;
}

.mt-20px {
  margin-top: 20px;
}

.ml-5px {
  margin-left: 5px;
}

.mt-5px {
  margin-top: 5px;
}

.mr-20px {
  margin-right: 20px;
}

.flex-space-between-flex-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pt-5px {
  padding-top: 5px;
}
.pt-3px {
  padding-top: 3px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mr-5px {
  margin-right: 5px;
}

.mb-16px {
  margin-bottom: 16px;
}
.mt-30px {
  margin-top: 30px;
}

.mb-20px {
  margin-bottom: 20px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.cursor-p {
  cursor: pointer;
}

.p-10px {
  padding: 10px;
}
.height-700px {
  height: 700px;
}

.height-20px {
  height: 20px;
}

.overflow-scroll {
  overflow: scroll;
}

.width-400px {
  width: 400px;
}
.width-150px {
  width: 150px;
}

.bold {
  font-weight: 900;
}

p {
  margin: 0px;
}
.width-50 {
  width: 50%;
}

.mr-5px {
  margin-right: 5px;
}

.ml-5px {
  margin-left: 5px;
}

.width-200px {
  width: 200px;
}

.p-20px {
  padding: 20px;
}
.ml-10px {
  margin-left: 10px;
}

.ml-16px {
  margin-left: 16px;
}

.ml-20px {
  margin-left: 20px;
}

.mt-10px {
  margin-top: 10px;
}

.m-0px {
  margin: 0px;
}

.width-100px {
  width: 100px;
}
.mr-10px {
  margin-right: 10px;
}

.mt-24px {
  margin-top: 24px;
}
